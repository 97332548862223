import * as Sentry from '@sentry/nuxt'

if (useRuntimeConfig()?.public?.ENABLE_SENTRY === 'true') {
  Sentry.init({
    dsn: useRuntimeConfig()?.public?.SENTRY_DSN,
    environment: useRuntimeConfig()?.public?.SITE_ENV,

    // We recommend adjusting this value in production, or using tracesSampler
    // for finer control
    tracesSampleRate: 1.0,
  })
}
